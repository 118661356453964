<template>
  <router-view/>
</template>

<style>
	img {
		width: auto;
		height: 100vh;
  }

  .square {
	/*border: 2px solid white;*/
	background: rgba(58,58,58,0.3);
	font-size: 100%;
	cursor: pointer;
	text-decoration: none;
	color: white;
	text-align: right;
	position: absolute;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 1.5rem;
	/*font-weight: 800;*/
	/* left: 1653px;
	top: -700px;
	width: 186px;
	height: 183px; */
  }
</style>
