import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import(/* webpackChunkName: "E9" */ '../views/e9.front.vue')
  },
  {
    path: '/frontal',
    name: 'E9.Fontal',
    component: () => import(/* webpackChunkName: "E9" */ '../views/e9.front.vue')
  },
  {
    path: '/posterior',
    name: 'E9.Posterior',
    component: () => import(/* webpackChunkName: "E9" */ '../views/e9.posterior.vue')
  },
  {
    path: '/e9/pb/services',
    name: 'E9.PB.Services',
    component: () => import(/* webpackChunkName: "E9" */ '../views/e9.pb.services.vue')
  },
  {
    path: '/e9/pb/gestion',
    name: 'E9.PB.Gestion',
    component: () => import(/* webpackChunkName: "E9" */ '../views/e9.pb.gestion.vue')
  },
  {
    path: '/e9/pb/audience',
    name: 'E9.PB.Audience',
    component: () => import(/* webpackChunkName: "E9" */ '../views/e9.pb.audience.vue')
  },
  {
    path: '/e9/pb/direction',
    name: 'E9.PB.Direction',
    component: () => import(/* webpackChunkName: "E9" */ '../views/e9.pb.direction.vue')
  },
  {
    path: '/e9/p1/prefecture',
    name: 'E9.P1.Prefecture',
    component: () => import(/* webpackChunkName: "P1" */ '../views/e9.p1.prefecture.vue')
  },
  {
    path: '/e9/p1/posgrade',
    name: 'E9.P1.Posgrade',
    component: () => import(/* webpackChunkName: "P1" */ '../views/e9.p1.posgrade.vue')
  },
  {
    path: '/e9/p1/21',
    name: 'E9.P1.21',
    component: () => import(/* webpackChunkName: "EP1" */ '../views/e9.p1.21.vue')
  },
  {
    path: '/e9/p2/celex',
    name: 'E9.P2.Celex',
    component: () => import(/* webpackChunkName: "P2" */ '../views/e9.p2.celex.vue')
  },
  {
    path: '/e9/p2/direction',
    name: 'E9.P2.Direction',
    component: () => import(/* webpackChunkName: "P2" */ '../views/e9.p2.direction.vue')
  },
  {
    path: '/e9/p3/direction',
    name: 'E9.P3.Direction',
    component: () => import(/* webpackChunkName: "P3" */ '../views/e9.p3.direction.vue')
  },
  {
    path: '/e9/p3/cubicle',
    name: 'E9.P3.Cubicle',
    component: () => import(/* webpackChunkName: "EP3" */ '../views/e9.p3.cubicle.vue')
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
